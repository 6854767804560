<template>
    <div class="billRecord">
        <rxNavBar title="历史交易" androidOrIOSFlag="true"></rxNavBar>


        <van-collapse v-model="billRecordName"  v-for="(item,index) in billRecordData"   @change="change">
            <van-collapse-item :key="index" :name="index"  style="border-bottom-right-radius: 10px;width: 100%">
                <template #title>
                    <div class="billTitle">交易日期：{{ item.payTime }}</div>
                    <div class="billTitle">支付方式：{{ item.payType }}</div>
                    <div class="billTitle">交易金额：<span class="titleMoney">￥{{ item.sumMoney }}</span></div>
                </template>
                <div class="contract">
                    <div class="staffRenttitle">
                        <van-row justify="center">
                            <van-col span="5">状态</van-col>
                            <van-col span="5">类型</van-col>
                            <van-col span="7">金额</van-col>
                            <van-col span="6">备注</van-col>
                        </van-row>
                    </div>
                    <div class="content"  v-for="items,index in item.historyBillDetailList" :key="index">
                        <van-row style="position:relative">
                            <van-col span="5" class="billFee">{{items.payStates}}</van-col>
                            <van-col span="5" class="billFee">{{items.payType}} </van-col>
                            <van-col span="7" class="billFee">{{items.money}} </van-col>
                            <van-col span="6" class="billFee">{{items.billDescription }} </van-col>
                        </van-row>
                    </div>
               </div>
            </van-collapse-item>
            <div v-if="billRecordData.length==0" class="noneHistory">没有历史交易记录~</div>
        </van-collapse>
    </div>
</template>

<script>
import {
    NavBar,
    Picker,
    List,
    PullRefresh,
    Toast,
    Popup,
    Checkbox,
    CheckboxGroup,
    Collapse,
    CollapseItem,
    Col,
    Row,
} from "vant";
Vue.use(Picker);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Popup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
import {
  queryBaseData,
  userHistoryBillList,
} from "../../../getData/getData";
import {
  checkAndroid, checkIOS,openInWebview,
  getUserId,
  globaluserId,
  responseUtil
} from "../../../libs/rongxunUtil";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import Vue from 'vue';
import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile';
export default {
        components: {
        [NavBar.name]: NavBar,
        [List.name]: List,
        [PullRefresh.name]: PullRefresh,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        [Col.name]: Col,
        [Row.name]: Row,
        dialMobile,
        rxNavBar

    },
    data(){
        return{
            billRecordName:[], //当前展开面板的 name
            billRecordData:[      //折叠面板循环数组
            ],
        }
    },
    created(){
        // this.temporary()
        this.initData()
    },
    mounted(){
        // this.temporary()
        this.initData()
    },
    methods:{
      change(e){
        console.log(e)
        console.log(123)

      },
        temporary(){
            // for(let i in this.billRecordData){
            //     this.billRecordData[i].title = this.billRecordData[i].payTime + ' 支付方式：' + this.billRecordData[i].payType + ' 交易金额：' + this.billRecordData[i].sumMoney
            // }

        },
        initData(){
            let that = this
            let initData = {}
            initData.user_id = globaluserId()
            initData.billOwnerType = this.$route.query.billOwnerType
            userHistoryBillList(initData).then(function(response) {
                responseUtil.dealResponse(that, response, () => {
                    that.billRecordData = response.data.data.historyBillList
                    console.log(that.billRecordData)
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>
    @redius: 8px;
    .contract{
        position: relative;
        height: auto;
        background-color: white !important;
        border-radius: 8px;
        overflow: hidden;
        /* margin-bottom:10px; */
        padding:10px 10px 0 10px;
        margin-bottom:10px
    }

    .staffRenttitle .van-col{
        font-size:12px;
        font-weight:bold;
        padding:8px 10px;
        border:0.5px solid #fafafa;
        text-align: center;
        color: black;
    }

    .content .billFee,.totalCount .billFee{
        color: black;
        font-size:12px;
        padding:8px 0px;
        border:0.5px solid #fafafa;
        text-align: center;
    }
    .content{
        font-size:12px;
    }

    .billTitle{
        // font-size: 16px;
        // background-color: #ffffff;
        font-weight: bolder;
    }
    .titleMoney{
        color: #ff5d3b;
    }
</style>
<style>
    .billRecord .van-cell{
        /* border-radius: 15px; */
        background-color:#f8f8f8 !important;
    }
    .billRecord .van-collapse-item__content{
        background-color:#f8f8f8 !important;

    }
   .billRecord .nopadding .van-cell{
        background-color:white !important;
    }
    .noneHistory{
        width: 100%;
        text-align: center;
        margin-top: 40%;
        color: #818181;
    }
</style>
